<template>
	<section
		v-if="available"
		id="pricing"
		:class="[
			'pricing',
			{ 'is-info': isInfo, 'is-product-card': isParentProductCard, 'collection-pricing': isCollection },
		]"
	>
		<template v-if="compareMulti(parsedMapRule, [5, 10]) && !Boolean(auth)">
			<a :href="`/account/login?reurl=${reurl()}`"> Sign In To See Price </a>
		</template>
		<template v-else>
			<pricing-display
				:price="getPrice"
				:price-msrp="getPriceMsrp"
				:can-show-percent="canShowPercent"
				:percent-savings="getPercentSavings"
				:product="inventory"
				:is-outboard="isOutboard"
				:core-price="getCorePrice"
				:braintree-gateway="braintreeGateway"
			>
				<slot></slot>
			</pricing-display>
		</template>
	</section>
</template>

<script>
import Money from '@/mixins/money.js';
import Product from '@/mixins/product.js';
import Garage from '@/mixins/garage.js';

export default {
	name: 'pricing',
	mixins: [Money, Product, Garage],
	components: {
		PricingDisplay: () => import('@/components/product-card/PricingDisplay.vue'),
	},
	props: {
		inventory: {
			type: Object,
			default: () => {},
			required: true,
		},
		cda: {
			type: Number,
			required: true,
		},
		auth: {
			type: Number,
			default: 0,
		},
		type: {
			type: Number,
			required: true,
		},
		docKey: {
			type: String,
			required: true,
		},
		isInfo: {
			type: Boolean,
			default: false,
		},
		corePrice: {
			type: Number,
			default: 0,
		},
		braintreeGateway: {
			type: String,
			require: true
		}
	},
	mounted() {
		this.checkForStoredRide();

		if (this.hasStoredRide) this.getStoredRide();
	},
	computed: {
		getCorePrice() {
			if (!this.corePrice) return null;
			return this.money(this.corePrice);
		},
		hasSinglePrice() {
			if (this.isCollection) {
				let priceRange = 'price_range';

				if (this.cda > 0) {
					priceRange = `price_lvl${this.cda}_range`;
				}

				if (Object.hasOwn(this.inventory, priceRange)) {
					// sometimes the price range exists within the inventory object,
					// but sometimes the value is null
					if (this.inventory[`${priceRange}`] === null) {
						return true;
					}
					if (Array.isArray(this.inventory[`${priceRange}`])) {
						return this.inventory[`${priceRange}`][0] === this.inventory[`${priceRange}`][1];
					}
					return false;
				}
        
				return true;
			} else {
				return true;
			}
		},
		getPrice() {
			if (this.parsedMapRule === 6) {
				return this.getPriceMsrp;
			}
			if (this.hasSinglePrice) {
				if (this.cda > 0) {
					if (Array.isArray(this.inventory[`price_lvl${this.cda}`])) {
						return this.money(this.inventory[`price_lvl${this.cda}`][1]);
					}
					return this.money(this.inventory[`price_lvl${this.cda}`]);
				}
				return this.price !== '' ? this.money(this.inventory.price) : this.money(this.inventory.price_display);
			} else {
				if (this.cda > 0) {
					return this.buildRange(this.inventory[`price_lvl${this.cda}_range`]);
				}
				return this.buildRange(this.inventory.price_range);
			}
		},
		getPriceMsrp() {
			if (Array.isArray(this.inventory.price_msrp)) {
				return this.money(this.inventory.price_msrp[1]);
			}

			if (this.inventory.hasOwnProperty('msrp')) {
				return this.money(this.inventory.msrp);
			}

			return this.money(this.inventory.price_msrp);
		},
		hasPercentSavings() {
			if (!this.hasSinglePrice) return false;
			if (Array.isArray(this.getPrice)) {
				if (this.getPrice[0] === this.getPriceMsrp) return false;
			} else {
				if (this.getPrice === this.getPriceMsrp) return false;
			}

			return true;
		},
		canShowPercent() {
			if (this.hasPercentSavings) {
				// if the percent savings is less than 6%, don't show it
				if (this.getPercentSavings < 6) return false;

				return this.compareMulti(this.parsedMapRule, [0, 9]) || this.parsedMapRule !== 2;
			}
			return false;
		},
		getPercentSavings() {
			if (this.hasPercentSavings) {
				let msrp = Number(this.getPriceMsrp.replaceAll(',', ''));
				let difference = msrp - Number(this.getPrice.replaceAll(',', ''));
				let percentRaw = difference / msrp;
				let percentage = Math.ceil(percentRaw * 100);

				return percentage;
			}
			return null;
		},
		isOutboard() {
			return parseInt(this.type) === 3;
		},
		isParentProductCard() {
			if (this.$parent.$options._componentTag === 'inventory-info') {
				return this.$parent.$parent.$options._componentTag === 'product-card';
			}

			return this.$parent.$options._componentTag === 'product-card';
		},
	},
	methods: {
		buildRange(range) {
			let low, high;
			if (Array.isArray(range)) {
				low = range[0];
				high = range[1];
			} else {
				range = range.split(', ');
				low = range[0];
				high = range[1];
			}
			// build price range with passed low & high attributes
			if (low === high) return `$${this.money(low)}`;
			// if low is equal to 0 or null, show high price
			// if high price is null, show msrp
			if (parseInt(low) === 0 || low === null) {
				if (high === null) {
					return `$${this.getPriceMsrp()}`;
				} else {
					return `$${this.money(high)}`;
				}
			} else {
				// if everything is good, show both
				return `${this.money(low)} - $${this.money(high)}`;
			}
		},
	},
};
</script>

<style lang="scss">
.strikethrough {
	text-decoration: line-through;
}
.pricing {
	.sell-price {
		color: #222;
		color: color(display-p3 0.1333 0.1333 0.1333);
		font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
			Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
	&:is(.is-product-card) {
		.sell-price {
			font-size: calc(24 / var(--default-font-size, 14) * 1rem);
		}
	}
	&:not(.is-product-card) {
		.sell-price {
			font-size: calc(32 / var(--default-font-size, 14) * 1rem);
		}
	}
}
</style>
