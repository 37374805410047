import Cookies from 'js-cookie';
import axios from 'axios';

export default {
	data() {
		return {
			localGarage: [],
			storedRide: null,
			hasStoredRide: false,
			addingToGarage: false,
			addedToGarage: false,
		};
	},

	computed: {
		localGarageFiltered() {
			if (!this.hasStoredRide) return this.localGarage;

			return this.localGarage.filter((g) => {
				return this.getGarageId(g) !== this.getGarageId(this.storedRide);
			});
		},
		storageName() {
			if (this.partFinder) {
				return this.site + '-' + this.brand + '-finder';
			}

			if (this.catalog) {
				return this.site + '-' + 'catalog-stored-ride';
			}

			return this.site + '-stored-ride';
		},
		storedRideName() {
			if (this.hasStoredRide) {
				if (this.storedRide.name) return this.storedRide.name;
				if (Array.isArray(this.storedRide)) {
					if (this.storedRide[0].make) {
						return `${this.storedRide[0].year} ${this.storedRide[0].make} ${this.storedRide[0].model}`;
					}
				}
				if (this.storedRide.make) {
					return `${this.storedRide.year} ${this.storedRide.make} ${this.storedRide.model}`;
				}
				if (this.storedRide.values) {
					const year = this.findStoredRideValueEntry('Year');
					const make = this.findStoredRideValueEntry('Make');
					const model = this.findStoredRideValueEntry('Model');
					return `${year} ${make} ${model}`;
				}
			}
			return null;
		},
		storedRideEngine() {
			if (this.hasStoredRide) {
				if (this.storedRideCategory?.toLowerCase() === 'automotive') {
					if (this.storedRide.engine) return this.storedRide.engine.trim();
					if (Array.isArray(this.storedRide)) {
						if (this.storedRide[0].engine) return this.storedRide[0].engine.trim();
					}
					if (this.storedRide.values) {
						const submodel = this.findStoredRideValueEntry('Submodel').replace(/-/g, '');
						if (submodel.includes(this.findStoredRideValueEntry('Model'))) {
							// remove the model from the submodel
							return submodel.replace(this.findStoredRideValueEntry('Model'), '').trim();
						}
						return submodel.trim();
					}
				}
				return null;
			}
			return null;
		},
		storedRideCategory() {
			if (this.hasStoredRide) {
				if (this.storedRide.category) return this.storedRide.category;
				if (Array.isArray(this.storedRide)) {
					if (this.storedRide[0].category) return this.storedRide[0].category;
				}
				if (this.storedRide.values) {
					return this.findStoredRideValueEntry('Category');
				}
			}
			return null;
		},
		storedRideModel() {
			if (this.hasStoredRide) {
				if (this.storedRide.model) return this.storedRide.model;
				if (Array.isArray(this.storedRide)) {
					if (this.storedRide[0].model) return this.storedRide[0].model;
				}
				if (this.storedRide.values) {
					return this.findStoredRideValueEntry('Model');
				}
			} else if (window.location.pathname.includes('catalog/v1')) {
				const data = window.location.pathname
					.split('/')
					.find((entry) => entry.includes('-'))
					.replaceAll('-', ' ');
				return data.split(' ').at(-1);
			}
			return null;
		},
		garageId() {
			if (this.hasStoredRide) {
				if (Array.isArray(this.storedRide)) {
					if (this.storedRide[0].garage_id.id) return this.storedRide[0].garage_id.id;
				}
				if (this.storedRide.garage_id) {
					if (this.storedRide.garage_id.id) return this.storedRide.garage_id.id;
				}
			}
			return null;
		},
		garageFitmentId() {
			if (this.hasStoredRide) {
				if (Array.isArray(this.storedRide)) {
					if (this.storedRide[0].id) return this.storedRide[0].id;
					else if (this.storedRide[0].garage_id.fitment) return this.storedRide[0].garage_id.fitment;
				}
				if (this.storedRide.id) return this.storedRide.id;
				if (this.storedRide.garage_id) {
					if (this.storedRide.garage_id.fitment) return this.storedRide.garage_id.fitment;
				}
				// sometimes the backend only provides this
				if (this.storedRide.catalog) {
					if (this.storedRide.catalog.fitment) return this.storedRide.catalog.fitment;
				}
			}
			return null;
		},
	},

	methods: {
		checkForStoredRide() {
			if (Cookies.get('selected-ride')) {
				this.hasStoredRide = true;
			} else {
				this.hasStoredRide = !!localStorage.getItem(this.storageName); // turns the value into a boolean
			}

			if (!Cookies.get('selected-ride') && this.hasStoredRide) {
				this.getLocalGarage();
				this.getStoredRide();

				if (this.garageId !== null) {
					this.localGarage.forEach((ride, index) => {
						if (ride.id === this.garageId) {
							this.localGarage.splice(index, 1);
						}
					});
				}

				this.updateLocalGarage();

				this.hasStoredRide = false;
				this.storedRide = null;
			}
		},
		getStoredRide() {
			if (Cookies.get('selected-ride')) {
				let cookie = Cookies.get('selected-ride');
				let cookieSanitized = cookie.replace(/\+/g, ' ');

				this.storedRide = JSON.parse(cookieSanitized);
				return;
			}

			this.storedRide = JSON.parse(localStorage.getItem(this.storageName));
		},
		setStoredRide(ride, storage = false) {
			this.storedRide = ride;

			//   if (!this.partFinder) {
			// 	Cookies.set("selected-ride", JSON.stringify(this.storedRide));
			//   }

			localStorage.setItem(storage ? storage : this.storageName, JSON.stringify(this.storedRide));
			this.checkForStoredRide();

			// this.$emit("new-ride");
		},
		clearStoredRide() {
			localStorage.removeItem(this.storageName);
			this.storedRide = null;
			this.hasStoredRide = false;

			Cookies.remove('selected-ride');
			// this.loading = true;
			// this.$emit("found", null);

			// EventBus.$emit("init");
			// this.$emit("new-ride");
		},
		getLocalGarage() {
			if (localStorage.getItem(this.site + '-local-garage') !== null) {
				let garage = [];
				let storage = JSON.parse(localStorage.getItem(this.site + '-local-garage'));
				if (Array.isArray(storage)) {
					garage = storage;
				}

				this.localGarage = garage.filter((ride) => ride !== null);
			}

			if (this.localGarage.length === 0) localStorage.removeItem(this.site + '-local-garage');
		},
		updateLocalGarage(updatedGarage = null) {
			return new Promise((resolve, reject) => {
				if (updatedGarage !== null) {
					localStorage.setItem(this.site + '-local-garage', JSON.stringify(updatedGarage));
					resolve();
				} else {
					localStorage.setItem(this.site + '-local-garage', JSON.stringify(this.localGarage));
					resolve();
				}
				reject('Failed to update local garage');
			});
		},
		async getBackendGarage() {
			const options = {
				method: 'GET',
			};
			await fetch('/garage/api/summary', options)
				.then((response) => {
					if (response.ok) {
						return response.json();
					}
				})
				.then((results) => {
					this.localGarage = results;
				});
		},
		removeParam(key) {
			history.replaceState &&
				history.replaceState(
					null,
					'',
					location.pathname +
						location.search.replace(new RegExp(`[\?&]${key}=[^&]+`), '').replace(/^&/, '?') +
						location.hash
				);
		},
		removeFitmentParam() {
			this.removeParam('fitment');
		},
		removeUseFitmentParam() {
			this.removeParam('use_fitment');
		},
		findStoredRideValueEntry(type) {
			if (this.hasStoredRide) {
				if (this.storedRide.values) {
					return Object.values(this.storedRide.values.find((ride) => Object.keys(ride) == type)).join('');
				}
			}
			return null;
		},
		async addToGarage(ride) {
			this.addedToGarage = false;
			this.addingToGarage = true;
			const test = window.location.hostname.includes('localhost') ? 'localhost' : '';

			const id = this.getRideFitmentId(ride);

			try {
				const response = await fetch(`${test}/garage/add/${id}?api=1`);
				if (!response.ok) {
					throw response;
				}
				return response.json();
			} catch (error) {
				Sentry.captureException(error);
				console.warn(error);
				this.addingToGarage = false;
				throw error.message
					? error.message
					: "We're sorry, we ran into an issue adding this ride to your garage. Please try again.";
			}
		},
		addToLocalGarage() {
			let garage = [];

			if (localStorage.getItem(this.site + '-local-garage') !== null) {
				garage = JSON.parse(localStorage.getItem(this.site + '-local-garage'));
			}

			garage.push(this.storedRide);

			localStorage.setItem(this.site + '-local-garage', JSON.stringify(garage));
			this.localGarage = JSON.parse(localStorage.getItem(this.site + '-local-garage'));
		},
		getRideFitmentId(ride) {
			let id = null;
			if (ride.garage_id && ride.garage_id.fitment) {
				id = ride.garage_id.fitment;
			} else if (ride.id) {
				id = ride.id;
			} else if (ride.catalog) {
				id = ride.catalog.fitment;
			}

			if (id == undefined || id == null || id == '') {
				throw Error('Sorry, there was an unexpected problem looking up your model.');
			}

			return id;
		},
		getGarageId(ride) {
			if (ride) {
				if (ride.garage_id && ride.garage_id.id) {
					return ride.garage_id.id;
				} else if (ride.id) {
					return ride.id;
				} else if (ride.catalog) {
					if (ride.catalog.id) {
						return ride.catalog.id;
					}
					if (ride.catalog.fitment && !ride.catalog.id) {
						return ride.catalog.fitment;
					}
				}
			}

			return null;
		},
		async checkRideInLocalGarage(ride) {
			return new Promise((resolve, reject) => {
				for (let index = 0; index < this.localGarage.length; index++) {
					const garageRide = this.localGarage[index];
					if (
						parseInt(this.getRideFitmentId(garageRide)) === parseInt(this.getRideFitmentId(ride)) ||
						parseInt(this.getGarageId(garageRide)) === parseInt(this.getGarageId(ride))
					) {
						this.setStoredRide(garageRide);
						this.updateSelected(ride);
						resolve(true);
						return;
					}
				}
				resolve(false);
			});
		},
		updateSelected(ride) {
			/**
			 * instead of using the computed property: this.garageFitmentId
			 * from the garage mixin,
			 * use the passed ride
			 */
			 const id = this.getRideFitmentId(ride);
			axios
				.post(`/garage/update/${id}`)
				.then((results) => {
					this.checkForStoredRide();

					if (this.hasStoredRide) {
						this.getStoredRide();
					}
				})
				.catch((error) => {
					Sentry.withScope(function (scope) {
						scope.setExtras({ 'Fitment ID': id });
						Sentry.captureException(error);
					});
					console.error('There was an error with the update garage request: ', error.message);
				})
				.finally(() => {
					// redirect
					this.checkRedirect();
				});
		},
		checkRedirect() {
			// if redirect is enabled
			if (this.redirect) {
				// is the user already on a catalog product page?
				const pageTypes = ['catalog-product', 'product', 'collection'];
				if (pageTypes.some((type) => location.pathname.includes(type))) {
					const urlParams = new URLSearchParams(location.search);
					const preserveKeys = ['ref', 'use_fitment'];

					// remove all parameters except the ones we want preserved
					for (const key of [...urlParams.keys()]) {
						if (!preserveKeys.includes(key)) {
							urlParams.delete(key);
						}
					}

					// ensure `use_fitment` exists in the URL parameters and set the cookie
					// if (!urlParams.has('use_fitment')) {
					// 	urlParams.set('use_fitment', 1);
					// 	Cookies.set('use_fitment', 1);
					// }
					// update the browser history state with the new parameters
					const updatedUrl = `${location.pathname}?${urlParams}`;
					history.pushState({ use_fitment: urlParams.get('use_fitment') }, '', updatedUrl);

					// reload the page to apply changes
					location.reload();
					return;
				}
				// relocate to catalog page specific for ride
				else {
					this.shopForCurrent('New Ride Selected');
				}
			}
			// if redirect is disabled
			else {
				if (location.pathname.includes('search')) {
					const url = new URLSearchParams(location.search);
					if (!url.has('use_fitment')) {
						url.set('use_fitment', 1);
						Cookies.set('use_fitment', 1);
					}
					const stateObject = { use_fitment: 1 };
					const urlString = url.toString();
					history.pushState(stateObject, '', `${location.pathname}?${urlString}`);
				}
				location.reload();
			}
		},
		isRideSelected(ride) {
			if (this.storedRide == null) {
				return false;
			}

			return this.getRideFitmentId(ride) == this.getRideFitmentId(this.storedRide);
		},
		isRideLoading(ride) {
			if (this.isRideSelected(ride) && this.loading) {
				return true;
			}

			return false;
		},
		isRideBeingCleared(ride) {
			if (this.isRideSelected(ride) && this.clearing && this.isRideInClearList(ride)) {
				return true;
			}

			if (this.isRideInClearList(ride) && this.clearing) {
				return true;
			}

			return false;
		},
		isRideInClearList(ride) {
			if (this.clearList.length > 0) {
				for (let index = 0; index < this.clearList.length; index++) {
					const rideBeingCleared = this.clearList[index];
					if (rideBeingCleared === this.getGarageId(ride)) {
						return true;
					}
				}
			}

			return false;
		},
		getRideName(ride) {
			if (ride.year && ride.make && ride.model) {
				return `${ride.year} ${ride.make} ${ride.model}`;
			}
			if (ride.name) {
				return ride.name;
			}

			if (ride.values) {
				const year = this.findStoredRideValueEntry('Year');
				const make = this.findStoredRideValueEntry('Make');
				const model = this.findStoredRideValueEntry('Model');
				return `${year} ${make} ${model}`;
			}

			return null;
		},
		getRideSubmodel(ride) {
			if (ride.submodel) {
				const submodel = ride.submodel.replace(/-/g, '');
				if (submodel.includes(ride.model)) {
					// remove the model from the submodel
					return submodel.replace(ride.model, '').trim();
				}
				return submodel;
			}

			if (ride.values) {
				const submodel = this.findRideValueEntry(ride.values, 'Submodel');
				if (submodel !== null) {
					// remove the model from the submodel
					return submodel.replace(this.findRideValueEntry(ride.values, 'Model'), '').trim();
				}
				return null
			}

			return null;
		},
		findRideValueEntry(values, type) {
			if (values && values.length) {
				return Object.values(values.find((value) => Object.keys(value) == type)).join('');
			}
			return null;
		},
	},
};
