<template>
	<div v-if="inventory">
		<!-- COLLECTIONS ONLY -->
		<div v-if="isCollection && available && !productFits && !isInfo">
			<a
				:href="'/' + docKey"
				class="btn btn-default text-white"
				:class="{ 'btn-block': block, 'btn-sm': small }"
				@click.prevent="trackProductClick"
			>
				View Details
			</a>
		</div>

		<!-- NORMAL ADD TO CART-->
		<form
			class="add-form"
			:class="{ 'noMargin': automotive }"
			ref="form"
			v-if="(canShowAddToCart && !canShowViewProduct) || isInfo"
			@submit.prevent="trackCartSubmit('submitted-add-to-cart')"
			style="padding-left: 0"
		>
			<input
				type="hidden"
				name="id"
				:value="id"
				v-if="!hasKit"
			/>
			<input
				type="hidden"
				name="qty"
				v-model="qty"
				v-if="!hasKit"
			/>
			<input
				type="hidden"
				name="addcartbulk"
				value="1"
				v-if="hasKit"
			/>
			<input
				type="hidden"
				name="pid[]"
				:value="idEncoded"
				v-if="hasKit"
			/>
			<input
				type="hidden"
				name="pid[]"
				:value="kitIdEncoded"
				v-if="hasKit"
			/>
			<input
				type="hidden"
				name="qty[]"
				:value="qty"
				v-if="hasKit"
			/>
			<input
				type="hidden"
				name="qty[]"
				:value="qty"
				v-if="hasKit"
			/>
			<input
				type="hidden"
				name="sobj"
				:value="pixel"
			/>
			<button
				class="btn btn-default add-to-cart-btn"
				:class="{ 'btn-block': block, 'btn-sm': small, 'btn-xs btn-primary': isPropResult, 'automotive': automotive }"
				type="submit"
				:data-sku="sku"
				:data-price_display="parseFloat(price).toFixed(2)"
				:disabled="disabled"
				:title="title"
				data-toggle="modal"
				data-target="#addToCartModal"
			>
				<transition
					name="fade"
					mode="out-in"
				>
					<span
						v-if="
							parsedMapRule === 10 &&
							Boolean(auth) &&
							productData.manufacturer.toLowerCase() !== 'kawasaki'
						"
						key="auth"
					>
						<transition
							name="fade"
							mode="out-in"
						>
							<span
								v-if="added"
								key="added"
							>
								Added&nbsp;<i class="fa fa-w fa-check"></i>
							</span>
							<span
								v-else
								key="text"
							>
								See Price in Cart
								<transition
									name="fade"
									mode="out-in"
								>
									<span v-if="loading">
										<!-- fontawesome icon: spin -->
										<i class="fa fa-spinner fa-spin"></i>
									</span>
								</transition>
							</span>
						</transition>
					</span>
					<span
						v-else-if="added"
						key="addedToCart"
					>
						Added&nbsp;<i class="fa fa-w fa-check"></i>
					</span>
					<span
						v-else-if="parsedMapRule !== 2"
						key="addToCart"
					>
						Add to Cart
						<transition
							name="fade"
							mode="out-in"
						>
							<span v-if="loading">
								<!-- fontawesome icon: spin -->
								<i class="fa fa-spinner fa-spin"></i>
							</span>
						</transition>
					</span>
					<span
						v-else
						key="seePriceInCart"
						>See Price in Cart</span
					>
				</transition>
			</button>

			<!-- <a
				v-if="isInfo"
				:href="addToListUrl"
				class="save-for-later-btn"
				@mouseenter="isHovering = !isHovering"
				@mouseleave="isHovering = !isHovering"
			>
				<i
					class="fa"
					:class="{
						'fa-heart-o': isHovering === false,
						'fa-heart': isHovering === true,
					}"
				></i>
				Save For Later
			</a> -->
		</form>

		<div v-else-if="(canShowViewProduct || !available) && !isInfo">
			<a
				:href="'/' + docKey"
				class="btn btn-default text-white srch-track-view-product"
				:class="{ 'btn-block': block, 'btn-sm': small, 'automotive': automotive }"
				@click.prevent="trackProductClick"
				v-if="!isOutboard"
			>
				View Product
			</a>
			<div v-else>
				<a
					class="btn btn-primary btn-block flex-center"
					href="tel:8772397580"
				>
					<span
						v-if="parsedMapRule === 7"
						style="color: white"
					>
						Order Desk: (877)239-7580
					</span>
					<span
						v-if="parsedMapRule === 9"
						style="color: white"
					>
						Order Desk: (877)239-7580
					</span>
				</a>
				<!-- <a
					:href="'/' + docKey"
					class="btn btn-link view-info-btn"
					:class="{ 'btn-block': block, 'btn-sm': small }"
					@click.prevent="trackProductClick"
				>
					View Info
				</a> -->
				<div class="flex-center justify-between margin-top-small">
					<button
						class="btn btn-default btn-sm contact-us-btn"
						data-toggle="modal"
						data-target="#contactUsModal"
					>
						Contact Us
					</button>
					<button
						v-if="withinBusinessHours"
						class="btn btn-default btn-sm contact-us-btn border-left"
						onclick="window.Genesys('command', 'Messenger.open')"
					>
						Chat Now
					</button>
				</div>
				<keep-alive>
					<contact-us-modal
						:captcha-site-key="captchaSiteKey"
						:sku="sku"
						:doc-key="docKey"
						:product-name="name"
					/>
				</keep-alive>
			</div>
		</div>
	</div>
</template>

<script>
import sha1 from 'js-sha1';
import Money from '../../mixins/money.js';
import Product from '../../mixins/product.js';
import Garage from '../../mixins/garage.js';
import ContactUsModal from '../widgets/ContactUsModal.vue';
import axios from 'axios';

export default {
	name: 'add-to-cart',
	components: { ContactUsModal },
	mixins: [Product, Money, Garage],
	data() {
		return {
			isHovering: false,
			formData: [],
			added: false,
			loading: false,
		};
	},
	props: {
		id: { type: String },
		site: { type: String },
		docKey: { type: String },
		inventory: { type: Object },
		sku: { type: String },
		name: { type: String },
		cda: { type: Number },
		type: { type: Number },
		auth: { type: Number },
		block: { type: Boolean, default: true },
		disabled: { type: Boolean, default: false },
		small: { type: Boolean, default: false },
		qty: { type: Number, default: 1 },
		eventCategory: { type: String },
		pixel: { type: String, default: null },
		ride: { type: Object },
		isInfo: { type: Boolean, default: false },
		kitId: { type: String },
		hasKit: { type: Boolean },
		infoPage: { type: Boolean, default: false },
		isPropResult: { type: Boolean },
		kitPrice: { type: String, default: null },
		kitType: { type: String, default: null },
		isRecommendationFeed: { type: Boolean, default: false },
		captchaSiteKey: { type: String, default: null },
		title: { type: String, default: null },
		automotive: { type: Boolean, default: false },
	},

	computed: {
		ref() {
			return sha1(this.docKey);
		},
		canShowViewProduct() {
			if (this.automotive) return false;

			if (this.id === null) return true;

			if (this.isCollection) return false;

			if (this.parsedMapRule === 10 && Boolean(this.auth) == false) return true;

			if (this.parsedMapRule === 5 && Boolean(this.auth) == true) return false;

			if (this.isRecommendationFeed) return true;

			return this.compareMulti(this.parsedMapRule, [4, 5, 7, 9]) || this.parsedStatus === 0;
		},
		canShowAddToCart() {
			if (this.automotive) return true;

			if (this.available) {
				if (this.isCollection && !this.productFits) return false;
	
				if (!this.isCollection && this.productFits) return true;
	
				if (this.isCollection && this.productFits) return true;
	
				if (this.parsedMapRule === 0) return true;
	
				if (Number.isNaN(this.parsedMapRule)) return true;
	
				if (this.compareMulti(this.parsedMapRule, [5, 10]) && this.auth) return true;
	
				if (this.isRecommendationFeed) return false;
	
				return this.aftermarketMap || this.compareMulti(this.parsedMapRule, [1, 6, 8, 10]);
			}

			return false;
		},
		// productFits() {
		// //   if (this.garageEnabled) {
		// //     if (
		// //       Object.keys(this.inventory).includes("fitment") &&
		// //       this.ride !== null
		// //     ) {
		// //       return this.ride.catalog.fitment === this.inventory.fitment;
		// //     }
		// //   }
		// 	if (Object.keys(this.inventory).includes("fitment") && this.ride !== null) {
		// 		return this.inventory.fitment.includes(this.ride.catalog.fitment.toString());
		// 	}

		//   	return false;
		// },
		isOutboard() {
			return this.type === 3;
		},
		price() {
			let price = '';

			switch (this.cda) {
				case 1:
					price = this.inventory.price_lvl1;
					break;
				case 2:
					price = this.inventory.price_lvl2;
					break;
				case 3:
					price = this.inventory.price_lvl3;
					break;
				default:
					return this.inventory.price;
			}

			return price;
		},
		kitIdEncoded() {
			return btoa(this.kitId);
		},
		idEncoded() {
			return btoa(this.id);
		},
		addToCartUrl() {
			return `/cart/add?reurl=${this.reurl()}&ref=${this.ref}`;
		},
		addToListUrl() {
			return `/account/list/add/${this.idEncoded}?reurl=${this.reurl()}`;
		},
		standardizedEventCategory() {
			return this.eventCategory.toLowerCase().replaceAll(' ', '_');
		},
		withinBusinessHours() {
			var date = new Date(); // current time
			var hours = date.getHours();
			var mins = date.getMinutes();
			var day = date.getDay();

			return day >= 1 && day <= 5 && hours >= 8 && (hours < 17 || (hours === 17 && mins <= 30));
		},
	},

	methods: {
		getCookie(cname) {
			let name = cname + '=';
			let decodedCookie = decodeURIComponent(document.cookie);
			let ca = decodedCookie.split(';');
			for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length);
				}
			}
			return '';
		},
		trackProductClick() {
			this.gaEcommerceSelectItem();

			//   setTimeout(() => {
			//     window.location = this.fullPartUrl;
			//   }, 1000);

			// Fusion
			if (this.pixel) {
				axios.post('/search/pixel', { sobj: this.pixel, signal_type: 'click' });
			}

			this.$emit('sendEventToFusion', 'click');
		},
		trackCartSubmit(event) {
			this.loading = true;
			this.added = false;
			this.formData = new FormData(this.$refs.form);
			let _this = this;

			if (this.formData.get('pixel')) {
				if (this.formData.get('pixel') == '') {
					Sentry.captureMessage('Add to Cart Pixel is empty', 'debug');
				}
			}

			axios
				.post(this.addToCartUrl, this.formData)
				.catch((error) => {
					const vue = this;
					Sentry.withScope(function (scope) {
						scope.setTag('component', 'Add to Cart');
						scope.setTag('parent', vue.$parent.$options.name);
						scope.setExtras({
							product: vue.inventory,
						});
						Sentry.setFingerprint([vue.addToCartUrl, String(error.statusCode)]);
						Sentry.captureException(error);
					});
					console.error('Error adding to cart', error);
				})
				.then(() => {
					this.loading = false;
					window.root.updateCart();
				})
				.then(() => {
					this.added = true;
				})
				.then(() => {
					setTimeout(() => {
						this.added = false;
					}, 2500);
				});
			try {
				const event = new CustomEvent('add_to_cart', {
					product: {
						doc_key: this.docKey ? null : this.docKey,
						sku: this.inventory.sku ? this.inventory.sku : this.sku,
						price: parseFloat(this.price),
					},
				});

				window.document.dispatchEvent(event);

				this.gaEcommerceAddToCart();

				this.$emit('sendEventToFusion', 'add_to_cart');

				try {
					let cart_id = atob(_this.getCookie('cart_id'));

					let cipher = cart_id.split('-');
					if (cipher.length) {
						cart_id = cipher[0];
					} else {
						cart_id = null;
					}
				} catch (e) {}
			} catch (error) {
				console.error('Could not dispatch add_to_cart Event');
			}

			//   if (this.pixel) {
			//     axios.post("/search/pixel", {
			//       sobj: this.pixel,
			//       signal_type: "add_to_cart",
			//     });
			//   }
		},
		gaEcommerceSelectItem() {
			window.dataLayer.push({ ecommerce: null });

			let item = {};

			if (this.inventory.is_collection) {
				item = {
					item_name: 'Collection - ' + this.inventory.collection_name,
				};
			} else {
				item = {
					item_name: this.productName,
					item_id: this.inventory.sku,
					price: parseFloat(this.inventory.price),
				};
			}

			window.dataLayer.push({
				event:
					this.cda === 1
						? `select_item_cda__${this.standardizedEventCategory}`
						: `select_item__${this.standardizedEventCategory}`,
				ecommerce: {
					items: [item],
				},
			});
		},
		gaEcommerceAddToCart() {
			window.dataLayer = window.dataLayer || [];

			window.dataLayer.push({ ecommerce: null });

			if (this.hasKit) {
				window.dataLayer.push({
					event:
						this.cda === 1
							? `add_to_cart_cda__${this.standardizedEventCategory}`
							: `add_to_cart__${this.standardizedEventCategory}`,
					ecommerce: {
						items: [
							{
								item_name: this.inventory.product_name ? this.inventory.product_name : this.name,
								item_id: this.inventory.sku ? this.inventory.sku : this.sku,
								price: parseFloat(this.price),
							},
							{
								item_name: this.kitType,
								item_id: this.kitId,
								price: parseFloat(this.kitPrice.replace('$', '')),
							},
						],
					},
				});
			} else {
				window.dataLayer.push({
					event:
						this.cda === 1
							? `add_to_cart_cda__${this.standardizedEventCategory}`
							: `add_to_cart__${this.standardizedEventCategory}`,
					ecommerce: {
						items: [
							{
								item_name: this.inventory.product_name ? this.inventory.product_name : this.name,
								item_id: this.inventory.sku ? this.inventory.sku : this.sku,
								price: parseFloat(this.price),
							},
						],
					},
				});
			}
		},
	},

	beforeMount() {
		this.checkForStoredRide();

		if (this.hasStoredRide) {
			this.getStoredRide();
		}
	},
};
</script>

<style lang="scss">
form.add-form {
	margin-bottom: 0 !important;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
	& button.add-to-cart-btn {
		align-items: center;
		border-radius: 0;
		display: flex;
		justify-content: center;
		width: 100%;
		span {
			line-height: normal;
			font-family: 'Oswald', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
				Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
			text-transform: uppercase;
		}
	}
	&.noMargin {
		margin: 0;
	}
}

.automotive {
	min-width: 20rem;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.justify-between {
	justify-content: space-between !important;
}

.margin-top-small {
	margin-top: 1rem;
}

.border-left {
	border-left: 1px solid #e3e3e3;
	&:hover {
		border-left: 1px solid #e3e3e3;
	}
}

.view-info-btn,
.contact-us-btn {
	text-align: center !important;
}

.text-white {
	color: white !important;
}

.save-for-later-btn {
	&:hover {
		color: #951115;
		.fa-heart-o {
			color: #951115;
		}
	}
}

.save-for-later-btn,
.fa-heart-o {
	color: #222;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

@media screen and (max-width: 768px) {
	form.add-form {
		flex-direction: column;
		margin-top: 1rem;
		button.btn.btn-default {
			margin-right: 0;
		}
		a.btn.btn-primary {
			margin-top: 1rem;
		}
	}
}
</style>
