<template>
	<div>
		<div v-if="inventory && available">
			<div
				v-if="stock >= 1"
				class="productStock"
			>
				<span class="in-stock"> <i class="fa fa-fw fa-check-circle fa-regular"></i>&nbsp;In Stock </span>
				<span class="hidden-xs">&nbsp;•&nbsp;</span>
				<a
					href="javascript:void(0)"
					data-toggle="popover"
					data-trigger="focus"
					data-content="Ships today if ordered before 3:00PM EST Monday - Friday"
					data-container="body"
					data-placement="top"
					class="stock-link asap-shipment"
					v-if="locationCheck && !holiday"
				>
					Ships within 24 hrs.
				</a>
				<a
					href="javascript:void(0)"
					data-toggle="popover"
					data-trigger="focus"
					:data-content="'Expected to ship by ' + availabilityDates"
					data-container="body"
					data-placement="top"
					class="stock-link delayed-shipment"
					v-if="locationCheck && holiday"
				>
					<i class="fa fa-fw fa-clock fa-regular"></i>
					&nbsp;Ships in {{ minDays }} to {{ maxDays }} days
				</a>
				<a
					href="javascript:void(0)"
					data-toggle="modal"
					data-target="#shipping-address-modal"
					v-else-if="!locationCheck"
				>
					<i class="fa fa-map-marker-alt fa-fw"></i>&nbsp;Check Availability
				</a>
			</div>

			<div
				v-if="stock === 0"
				class="productStock"
			>
				<a
					href="javascript:void(0)"
					data-toggle="popover"
					data-trigger="focus"
					:data-content="'Expected in stock ' + availabilityDates"
					data-container="body"
					data-placement="top"
					class="stock-link delayed-shipment"
					v-if="locationCheck"
				>
					<i class="fa fa-fw fa-clock fa-regular"></i>
					&nbsp;Ships in {{ minDays }} to {{ maxDays }} days
				</a>

				<a
					href="javascript:void(0)"
					data-toggle="popover"
					data-trigger="focus"
					data-content="Stock and price vary by selected product."
					data-container="body"
					data-placement="top"
					class="stock-link text-success"
					v-else-if="isCollection && !singlePrice && !isInfo"
				>
					<i class="fa fa-info-circle fa-fw"></i>
					<strong>View Details</strong> for availability
				</a>

				<a
					href="javascript:void(0)"
					data-toggle="modal"
					data-target="#shipping-address-modal"
					v-else
				>
					<i class="fa fa-map-marker-alt fa-fw"></i>&nbsp;Check availability
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import Cookies from '@/mixins/cookies.js';
import Product from '@/mixins/product.js';

export default {
	name: 'stock',
	mixins: [Cookies, Product],
	props: {
		inventory: { type: Object },
		stockDayMin: { type: [String, Number], default: '1' },
		stockDayMax: { type: [String, Number], default: '2' },
		addMin: { type: String },
		addMax: { type: String },
		holiday: { type: Boolean },
		singlePrice: { type: Boolean, default: false },
		isInfo: { type: Boolean },
		dc: { type: String },
	},
	computed: {
		availabilityDates() {
			let min = this.addBusinessDays(Date.now(), this.minDays);
			let max = this.addBusinessDays(Date.now(), this.maxDays);

			min = new Intl.DateTimeFormat('en-US', {
				month: 'short',
				day: 'numeric',
			}).format(min);
			max = new Intl.DateTimeFormat('en-US', {
				day: 'numeric',
			}).format(max);
			return `${min} - ${max}`;
		},
		minDays() {
			if (this.holiday && this.stock === 0) {
				return (parseInt(this.stockDayMin) + parseInt(this.addMin)).toString();
			}

			if (this.holiday) return this.addMin;

			return this.stockDayMin ? this.stockDayMin : '1';
		},
		maxDays() {
			if (this.holiday && this.stock === 0) {
				return (parseInt(this.stockDayMax) + parseInt(this.addMax)).toString();
			}

			if (this.holiday) return this.addMax;

			return this.stockDayMax ? this.stockDayMax : '2';
		},
		locationCheck() {
			return true;
			//return (
			// this.hasCookie("user_avail_loc") && this.hasCookie("cart_ship_loc")
			//);
		},
		stock() {
			if (parseInt(this.dc) === 1) {
				if (this.inventory.dc1_stock) {
					return parseInt(this.inventory.dc1_stock);
				}
				if (this.inventory.stock_dc1_stock) {
					return parseInt(this.inventory.stock_dc1_stock);
				}
				return 0;
			} else if (parseInt(this.dc) === 2) {
				if (this.inventory.dc2_stock) {
					return parseInt(this.inventory.dc2_stock);
				}
				if (this.inventory.stock_dc2_stock) {
					return parseInt(this.inventory.stock_dc2_stock);
				}
				return 0;
			} else {
				// user global_stock/stock_global
				if (Object.keys(this.inventory).includes('stock_global')) {
					if (this.inventory.stock_global === null) return 0;

					return parseInt(this.inventory.stock_global);
				}

				if (Object.keys(this.inventory).includes('global_stock')) {
					if (this.inventory.global_stock === null) return 0;

					return parseInt(this.inventory.global_stock);
				}
			}

			return 0;
		},
	},
	methods: {
		addBusinessDays(originalDate, numDaysToAdd) {
			const Sunday = 0;
			const Saturday = 6;
			let daysRemaining = numDaysToAdd;

			const newDate = new Date(originalDate);

			while (daysRemaining > 0) {
				newDate.setDate(newDate.getDate() + 1);
				if (newDate.getDay() !== Sunday && newDate.getDay() !== Saturday) {
					daysRemaining--;
				}
			}

			return newDate;
		},
	},
};
</script>

<style lang="scss" scoped>
.productStock {
	.in-stock {
		color: #29c30f;
		color: color(display-p3 0.1647 0.7647 0.0588);
		font-size: 1em;
		font-weight: 600;
	}
	.asap-shipment,
	.delayed-shipment {
		color: #999;
		color: color(display-p3 0.6 0.6 0.6 / 1);
		font-size: 0.8em;
	}
}

@media screen and (max-width: 768px) {
	.stock-link {
		display: block;
	}
	
}
</style>
