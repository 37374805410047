export default {
	data() {
		return {
			errorMessage: '',
		};
	},
	methods: {
		async machineApiBase(body, endpoint) {
			const headers = new Headers({
				Authorization: `Bearer ${atob(this.autoSearchToken)}`,
				'Content-Type': 'application/json',
			});
			const options = {
				method: 'POST',
				headers,
				body: JSON.stringify(body),
			};
			const base = this.machineApiUrl.startsWith('https://')
				? this.machineApiUrl
				: `https://${this.machineApiUrl}`;
			const url = new URL(endpoint, base);
			const request = new Request(url, options);
			try {
				const response = await fetch(request);
				if (!response.ok) {
					throw new Error('Sorry, we could not find your vehicle information.');
				}
				return response.json();
			} catch (error) {
				throw error;
			}
		},
		async searchMachineApi(body, endpoint) {
			this.loading = true;

			try {
				const ride = await this.machineApiBase(body, endpoint);

				await this.validateThenAddRide(ride);
			} catch (error) {
				throw error;
			} finally {
				this.loading = false;
			}
		},
		async searchVin(vin, endpoint = '/api/vinSearch') {
			try {
				await this.searchMachineApi(vin, endpoint);
			} catch (error) {
				$('.vin-submit-btn').button('reset');
				return this.errorMessage = this.getErrorMessage(error);
			}
		},
		async searchLicensePlate(plate, endpoint = '/api/plateSearch') {
			try {
				await this.searchMachineApi(plate, endpoint);
			} catch (error) {
				$('.license-plate-submit-btn').button('reset');
				return this.errorMessage = this.getErrorMessage(error);
			}
		},
		async validateThenAddRide(ride) {
			try {
				const currentRide = await this.checkRideInLocalGarage(ride);
				if (currentRide) {
					this.$emit('new-ride');
					return;
				}
			} catch (error) {
				throw error;
				return;
			}

			await this.addToGarage(ride)
				.catch((error) => {
					throw this.getErrorMessage(error);
				})
				.then(() => {
					this.setStoredRide(ride);
				})
				.then(() => {
					this.addToLocalGarage();
				})
				.then(() => {
					this.addingToGarage = false;
					this.addedToGarage = true;
				})
				.then(() => {
					this.$emit('new-ride');
				})
				.then(() => {
					this.checkRedirect();
				})
				.catch((error) => {
					throw this.getErrorMessage(error);
				});
		},
		getErrorMessage(
			error,
			defaultMessage = "We're sorry, we ran into an issue adding this ride to your garage. Please try again."
		) {
			if (error) {
				if (!error.message) {
					return error;
				}
				return error.message;
			}
			return defaultMessage;
		},
	},
	computed: {
		makeModelData() {
			return {
				id: 'make-model',
				title: 'Make / Model',
				description: 'Provide your vehicle details below to get an exact fit for your ride:',
				props: {
					site: this.site,
					machineToken: this.solrToken,
					axiosBaseUrl: this.axiosBaseUrl,
					isAuthenticated: this.isAuthenticated,
					clearing: this.clearing,
					updating: this.updating,
					enableAutomotive: this.enableAutomotive,
				},
				event: 'selected',
				handler: this.selectedFitment,
				component: 'FitmentBar',
			};
		},
		licensePlateLookupData() {
			return {
				id: 'license-plate',
				title: 'License Plate',
				description: 'Provide your license plate details below to get an exact fit for your ride:',
				props: {
					site: this.site,
					machineToken: this.machineToken,
					axiosBaseUrl: this.axiosBaseUrl,
					isAuthenticated: this.isAuthenticated,
					clearing: this.clearing,
					updating: this.updating,
					error: this.errorMessage,
				},
				event: 'search:license-plate',
				handler: this.searchLicensePlate,
				component: 'LicensePlateLookup',
			};
		},
		vinLookupData() {
			return {
				id: 'vin-lookup',
				title: 'VIN Lookup',
				description: 'Enter your 17 digit VIN to get an exact fit for your ride:',
				props: {
					site: this.site,
					machineToken: this.machineToken,
					axiosBaseUrl: this.axiosBaseUrl,
					isAuthenticated: this.isAuthenticated,
					clearing: this.clearing,
					updating: this.updating,
					error: this.errorMessage,
				},
				event: 'search:vin',
				handler: this.searchVin,
				component: 'VinLookup',
			};
		},
	},
};
